import Keycloak from 'keycloak-js'

const KEY_CLOAK_URL =
  process.env.REACT_APP_KEY_CLOAK_URL ||
  'https://tdis-keycloak-authentication-budtf2b6akfzg4ek.southcentralus-01.azurewebsites.net/'

const REALM = process.env.REACT_APP_REALM || 'TDISMeta'

const CLIENTID = process.env.REACT_APP_CLIENT_ID || 'tdis-meta-dev'

const keycloak = new Keycloak({
  url: KEY_CLOAK_URL,
  realm: REALM,
  clientId: CLIENTID,
})

export default keycloak
