import React from 'react'
import ReactDOM from 'react-dom'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import keycloak from './keycloak'
import App from './App'
import './style/index.css'

const container = document.getElementById('root')

ReactDOM.render(
  <ReactKeycloakProvider
    authClient={keycloak}
    initOptions={{
      onLoad: 'check-sso', // Change from 'login-required' to 'check-sso'
      silentCheckSsoRedirectUri:
        window.location.origin + '/silent-check-sso.html',
    }}
    onEvent={(event, error) => {
      console.log('Keycloak event:', event)
      if (event === 'onAuthLogout') {
        console.log('User logged out')
      }
      if (error) console.error('Keycloak error:', error)
    }}
    onTokens={(tokens) => {
      console.log('Keycloak tokens:', tokens)
    }}
  >
    <App />
  </ReactKeycloakProvider>,
  container
)
