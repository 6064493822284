import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Route, Switch, Redirect } from 'react-router-dom'
import { Header } from './Header'
import { Flip, ToastContainer } from 'react-toastify'
import { AllMetadataRecords } from './AllMetadataRecords'
import { MetadataForm } from './MetadataForm'
import { CloseActiveDropdown } from './general/CloseActiveDropdown'
import { Instructions } from './Instructions'
import { UploadedView } from './UploadedView'
import { useKeycloak } from '@react-keycloak/web'
import { motion } from 'framer-motion'
import { Typography, Box } from '@mui/material'

export const USER_STORAGE_KEY = 'twdb_metadata_user'
const Wrapper = styled.div`
  width: 100%;
`
const AnimatedMessage = ({ message }) => (
  <Box
    component={motion.div}
    initial={{ opacity: 0, y: -20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 1 }}
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      textAlign: 'center',
      backgroundColor: '#f5f5f5',
    }}
  >
    <Typography
      variant="h4"
      style={{
        color: '#324755',
        fontWeight: 'bold',
        letterSpacing: '0.05em',
        textShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
      }}
    >
      {message}
    </Typography>
  </Box>
)
const AppWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
`

const AppRoutes = ({ accessToken }) => (
  <AppWrapper>
    <Header />
    <Switch>
      <Route path="/app/" exact component={AllMetadataRecords} />
      <Route path="/app/form/:recordId" component={MetadataForm} />
      <Route path="/app/view/:recordId" component={UploadedView} />
      <Route path="/app/instructions/:recordId" component={Instructions} />
      <Redirect to="/app/" />
    </Switch>
  </AppWrapper>
)

export const Main = () => {
  const { keycloak, initialized } = useKeycloak()
  const [accessToken, setAccessToken] = useState(() => {
    const storedUserInfo = JSON.parse(localStorage.getItem(USER_STORAGE_KEY))
    return storedUserInfo?.accessToken || null
  })

  useEffect(() => {
    if (!initialized) {
      console.log('Keycloak not initialized yet.')
      return
    }

    if (keycloak.authenticated) {
      const token = keycloak.token
      if (token !== accessToken) {
        setAccessToken(token)

        // Store user info in localStorage
        const userInfo = {
          id: keycloak.tokenParsed.sub,
          username: keycloak.tokenParsed.preferred_username,
          email: keycloak.tokenParsed.email,
          roles: keycloak.tokenParsed.realm_access?.roles || [],
          accessToken: token,
        }
        localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(userInfo))
      }

      // Set up token refresh
      keycloak.onTokenExpired = async () => {
        try {
          await keycloak.updateToken(30)
          const newToken = keycloak.token
          setAccessToken(newToken)

          // Update localStorage with refreshed token
          const updatedUserInfo = {
            ...JSON.parse(localStorage.getItem(USER_STORAGE_KEY)),
            accessToken: newToken,
          }
          localStorage.setItem(
            USER_STORAGE_KEY,
            JSON.stringify(updatedUserInfo)
          )
        } catch (error) {
          console.error('Failed to refresh token:', error)
          keycloak.logout()
        }
      }
    } else {
      if (!localStorage.getItem(USER_STORAGE_KEY)) {
        keycloak.login()
      }
    }
  }, [initialized, keycloak])

  if (!initialized) {
    return <AnimatedMessage message="Loading Keycloak..." />
  }

  if (!accessToken) {
    return <AnimatedMessage message="Redirecting to Login..." />
  }

  return (
    <Wrapper>
      <ToastContainer
        autoClose={3000}
        position="bottom-right"
        transition={Flip}
        hideProgressBar
      />
      <CloseActiveDropdown />
      <main>
        <Switch>
          <Route
            path="/app"
            render={() => <AppRoutes accessToken={accessToken} />}
          />
          <Redirect to="/app" />
        </Switch>
      </main>
    </Wrapper>
  )
}
